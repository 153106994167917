import React from "react";
import CementryDesktop from "../components/cementry/Desktop";
import CementryMobile from "../components/cementry/Mobile";
import useDimensions from "../hooks/useDimensions";
import "../css/cementery.css";
import { graphql } from "gatsby";

const Cemetery = ({ data }) => {
  const [width] = useDimensions();
  const cemeteryTemp = data.sanityPage.cemeteryTemp;

  return (
    <div className="cementery_bg">
      {width &&
        (width > 768 ? (
          <CementryDesktop data={cemeteryTemp} />
        ) : (
          <CementryMobile data={cemeteryTemp} />
        ))}
    </div>
  );
};

export const query = graphql`
  query MyCemeteryQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      cemeteryTemp {
        back {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        cemeteryLogo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        scary {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        coolFlash {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          videoUrl
          slug {
            current
          }
        }
        hair {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          videoUrl
          slug {
            current
          }
        }
        passportGhost {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          videoUrl
          slug {
            current
          }
        }
        doodleOfDoom {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          videoUrl
          slug {
            current
          }
        }
        actShow {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          videoUrl
          slug {
            current
          }
        }
        schoolPhoto {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          videoUrl
          slug {
            current
          }
        }
        quoteRat {
          title
          isModal
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          videoUrl
          slug {
            current
          }
        }
      }
    }
  }
`;
export default Cemetery;
