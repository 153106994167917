import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Menu from "../common/Menu";
import Modal from "./Modal";

const CementryMobile = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    url: "",
  });

  function onClose() {
    setOpenModal(false);
  }

  function handleClick(title, url) {
    setModalData({
      title: title,
      url: url,
    });
    setOpenModal(true);
  }
  return (
    <div className="cemetery-mobile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-12">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
            <Link to="/">
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || "back"}
                className="img-fluid cemetery-mobile-tatoo1"
                loading="eager"
                placeholder="blurred"
              />
            </Link>
            <br />
            <center>
              <Link to="/">
                <GatsbyImage
                  image={data.logo.asset.gatsbyImageData}
                  alt={data.logo.asset.altText || "Logo"}
                  className="img-fluid cemetery-mobile-tatoo2"
                  loading="eager"
                  placeholder="blurred"
                />
              </Link>
            </center>
            <br />
            <center>
              <GatsbyImage
                image={data.cemeteryLogo.asset.gatsbyImageData}
                alt={data.cemeteryLogo.asset.altText || "Cemetery Logo"}
                className="img-fluid cemetery-mobile-tatoo3"
                loading="eager"
                placeholder="blurred"
              />
            </center>
            <br />
            <center>
              <GatsbyImage
                image={data.scary.asset.gatsbyImageData}
                alt={data.scary.asset.altText || "Scary"}
                className="img-fluid cemetery-mobile-tatoo4"
                loading="eager"
                placeholder="blurred"
              />
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick(data.coolFlash.title, data.coolFlash.videoUrl)
              }
            >
              <GatsbyImage
                image={data.coolFlash.image.asset.gatsbyImageData}
                alt={data.coolFlash.image.asset.altText || "Cool Flash"}
                className="img-fluid cemetery-mobile-tatoo5"
                loading="eager"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="col-sm-4 col-4">
            <Link to={data.passportGhost.slug.current}>
              <GatsbyImage
                image={data.passportGhost.image.asset.gatsbyImageData}
                alt={data.passportGhost.image.asset.altText || "Passport Ghost"}
                className="img-fluid cemetery-mobile-tatoo6"
                loading="eager"
                placeholder="blurred"
              />
            </Link>
          </div>
          <div className="col-sm-4 col-4">
            <Link to={data.doodleOfDoom.slug.current}>
              <GatsbyImage
                image={data.doodleOfDoom.image.asset.gatsbyImageData}
                alt={data.doodleOfDoom.image.asset.altText || "Doodle of Doom"}
                className="img-fluid cemetery-mobile-tatoo7"
                loading="eager"
                placeholder="blurred"
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick(data.actShow.title, data.actShow.videoUrl)
              }
            >
              <GatsbyImage
                image={data.actShow.image.asset.gatsbyImageData}
                alt={data.actShow.image.asset.altText || "Act Show"}
                className="img-fluid cemetery-mobile-tatoo8"
                loading="eager"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="col-sm-4 col-4">
            <Link to={data.hair.slug.current}>
              <GatsbyImage
                image={data.hair.image.asset.gatsbyImageData}
                alt={data.hair.image.asset.altText || "Hair"}
                className="img-fluid cemetery-mobile-tatoo9"
                loading="eager"
                placeholder="blurred"
              />{" "}
            </Link>
          </div>
          <div className="col-sm-4 col-4">
            <Link to={data.schoolPhoto.slug.current}>
              <GatsbyImage
                image={data.schoolPhoto.image.asset.gatsbyImageData}
                alt={data.schoolPhoto.image.asset.altText || "School Photo"}
                className="img-fluid cemetery-mobile-tatoo10"
                loading="eager"
                placeholder="blurred"
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-12">
            <Link to={data.quoteRat.slug.current}>
              <GatsbyImage
                image={data.quoteRat.image.asset.gatsbyImageData}
                alt={data.quoteRat.image.asset.altText || "Quote Rat"}
                className="img-fluid cemetery-mobile-tatoo11"
                loading="eager"
                placeholder="blurred"
              />
            </Link>
          </div>
        </div>
      </div>
      {/* ArtShow */}
      <Modal open={openModal} onClose={onClose} data={modalData} />
    </div>
  );
};

export default CementryMobile;
