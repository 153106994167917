import React, { useState } from "react";
import Menu from "../common/Menu";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Modal from "./Modal";

const CementryDesktop = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    url: "",
  });

  function onClose() {
    setOpenModal(false);
  }

  function handleClick(title, url) {
    setModalData({
      title: title,
      url: url,
    });
    setOpenModal(true);
  }

  return (
    <div className="cemetery">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <Link to="/">
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || "back"}
                className="img-fluid cemetery-tatoo1"
                loading="eager"
                objectFit="contain"
              />
            </Link>
            <br />
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick(data.coolFlash.title, data.coolFlash.videoUrl)
              }
            >
              <GatsbyImage
                image={data.coolFlash.image.asset.gatsbyImageData}
                alt={data.coolFlash.image.asset.altText || "Cool Flash"}
                className="img-fluid cemetery-tatoo2"
                loading="eager"
                objectFit="contain"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <center>
              <Link to="/">
                <GatsbyImage
                  image={data.logo.asset.gatsbyImageData}
                  alt={data.logo.asset.altText || "Logo"}
                  className="img-fluid cemetery-tatoo3"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
              <br />
              <GatsbyImage
                image={data.cemeteryLogo.asset.gatsbyImageData}
                alt={data.cemeteryLogo.asset.altText || "Cemetery Logo"}
                className="img-fluid cemetery-tatoo4"
                loading="eager"
                objectFit="contain"
              />
              <br />
              <GatsbyImage
                image={data.scary.asset.gatsbyImageData}
                alt={data.scary.asset.altText || "Scary"}
                className="img-fluid cemetery-tatoo5"
                loading="eager"
                objectFit="contain"
              />
            </center>
          </div>
          <div className="col-lg-3 col-md-3">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-5">
            <Link to={data.hair.slug.current}>
              <GatsbyImage
                image={data.hair.image.asset.gatsbyImageData}
                alt={data.hair.image.asset.altText || "Hair"}
                className="img-fluid cemetery-tatoo6"
                loading="eager"
                objectFit="contain"
              />
            </Link>
          </div>
          <div className="col-lg-4 col-md-4">
            <center>
              <Link to={data.passportGhost.slug.current}>
                <GatsbyImage
                  image={data.passportGhost.image.asset.gatsbyImageData}
                  alt={
                    data.passportGhost.image.asset.altText || "Passport Ghost"
                  }
                  className="img-fluid cemetery-tatoo7"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
            </center>
          </div>
          <div className="col-lg-3 col-md-3">
            <Link to={data.doodleOfDoom.slug.current}>
              <GatsbyImage
                image={data.doodleOfDoom.image.asset.gatsbyImageData}
                alt={data.doodleOfDoom.image.asset.altText || "Doodle of Doom"}
                className="img-fluid cemetery-tatoo8"
                loading="eager"
                objectFit="contain"
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                handleClick(data.actShow.title, data.actShow.videoUrl)
              }
            >
              <GatsbyImage
                image={data.actShow.image.asset.gatsbyImageData}
                alt={data.actShow.image.asset.altText || "Act Show"}
                className="img-fluid cemetery-tatoo9"
                loading="eager"
                objectFit="contain"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <center>
              <Link to={data.schoolPhoto.slug.current}>
                <GatsbyImage
                  image={data.schoolPhoto.image.asset.gatsbyImageData}
                  alt={data.schoolPhoto.image.asset.altText || "School Photo"}
                  className="img-fluid cemetery-tatoo10"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
            </center>
          </div>
          <div className="col-lg-5 col-md-5">
            <Link to={data.quoteRat.slug.current}>
              <GatsbyImage
                image={data.quoteRat.image.asset.gatsbyImageData}
                alt={data.quoteRat.image.asset.altText || "Quote Rat"}
                className="img-fluid cemetery-tatoo11"
                loading="eager"
                objectFit="contain"
              />
            </Link>
          </div>
        </div>
      </div>
      {/* ArtShow */}
      <Modal open={openModal} onClose={onClose} data={modalData} />
    </div>
  );
};

export default CementryDesktop;
